import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import SVGInject from '@iconfu/svg-inject';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import FlexBox from 'src/components/common/flex-box/FlexBox';
import Icon from 'src/components/common/icon/Icon';
import Subtitle from 'src/components/common/subtitle/Subtitle';
import Text from 'src/components/common/text/Text';
import Link from 'src/components/common/link/Link';

const StyledLink = styled(Link)`
  color: var(--orange);
  margin-top: auto;

  &:hover {
    color: var(--orange);
  }
`;

const ArrowRight = styled(Icon)`
  margin-left: 1rem;
  margin-bottom: -0.3rem;
`;

const StyledText = styled(Text)`
  color: var(--gray-lighter);
  font-size: ${({ isMobile }) => (isMobile ? '1.4rem' : '1.6rem')};
`;

const StyledFlexBox = styled(FlexBox)`
  background-color: var(--purple-lighter);
  border-radius: 1.6rem;
  flex-shrink: 0;
  padding: ${({ isMobile }) => (isMobile ? '3rem 2rem' : '2rem')};
  transition: all 0.4s ease;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      &:hover {
        background-color: var(--purple);
        transition: all 0.4s ease;

        ${StyledText} {
          color: var(--white);
        }

        svg {
          .hover {
            stroke: var(--white);
          }
        }
      }
    `}
`;

const Card = ({ icon, title, text, callToAction, ...remainingProps }) => {
  if (typeof window === 'undefined') return null;

  const [isHover, setIsHover] = useState(false);
  const { sm: isMobile } = useBreakpoint();

  const { link, text: callToActionText } = callToAction;

  return (
    <StyledFlexBox
      isMobile={isMobile}
      flexDirection="column"
      alignItems="flex-start"
      alignSelf="stretch"
      justifyContent="flex-start"
      gap={isMobile ? '1rem' : '2rem'}
      width={isMobile ? '70%' : 'calc(25% - 2rem)'}
      onMouseOut={() => setIsHover(false)}
      onMouseOver={() => setIsHover(true)}
      {...remainingProps}
    >
      <Icon
        icon={icon}
        height="7rem"
        width="auto"
        onLoad={(e) => SVGInject(e.target)}
      />
      <Subtitle
        purple={isMobile ? true : !isHover}
        white={!isMobile && isHover}
      >
        {title}
      </Subtitle>
      <StyledText isMobile={isMobile}>{text}</StyledText>
      <StyledLink to={link}>
        {callToActionText}
        <ArrowRight icon="arrowRight" />
      </StyledLink>
    </StyledFlexBox>
  );
};

Card.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  callToAction: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
  }),
};

export default Card;
