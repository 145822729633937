import React from 'react';
import styled from '@emotion/styled';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useRenderTitle from 'src/hooks/useRenderTitle';

import Section from 'src/components/common/section/Section';
import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import Text from 'src/components/common/text/Text';
import FlexBox from 'src/components/common/flex-box/FlexBox';
import LinkAsButton from 'src/components/common/link-as-button/LinkAsButton';

import Card from './components/card/Card';

const StyledSection = styled(Section)`
  padding: ${({ isMobile }) => (isMobile ? '5rem 1.6rem' : '5rem 0')};
`;

const FeaturesWrapper = styled(FlexBox)`
  margin: 7.6rem 0 4.8rem 0;
  overflow-x: scroll;
`;

const StyledText = styled(Text)`
  color: var(--gray-text);
`;

const Features = () => {
  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();

  const title = t('sections.features.title', { returnObjects: true });
  const text = t('sections.features.text');
  const features = t('sections.features.features', { returnObjects: true });
  const { link, text: callToAction } = t('sections.features.call_to_action', {
    returnObjects: true,
  });

  const { render: renderTitle } = useRenderTitle({
    titleComponent: Title,
    highlightComponent: HighlightedText,
    text: title,
  });

  return (
    <StyledSection isMobile={isMobile}>
      <FlexBox
        flexDirection="column"
        justifyContent={isMobile ? 'flex-start' : 'center'}
        gap="2rem"
        width="100%"
      >
        {renderTitle()}
        <StyledText align={isMobile ? 'left' : 'center'}>{text}</StyledText>
      </FlexBox>
      <FeaturesWrapper gap="2rem" wrap="nowrap">
        {features.map(
          (
            {
              icon,
              title,
              text,
              call_to_action: { link, text: callToActionText },
            },
            index,
          ) => (
            <Card
              key={`card-${index}`}
              icon={icon}
              title={title}
              text={text}
              callToAction={{ link, text: callToActionText }}
            />
          ),
        )}
      </FeaturesWrapper>
      <FlexBox alignItems="center" justifyContent="center">
        <LinkAsButton secondary to={link}>
          {callToAction}
        </LinkAsButton>
      </FlexBox>
    </StyledSection>
  );
};

export default Features;
