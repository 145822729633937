import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import SVGInject from '@iconfu/svg-inject';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import FlexBox from 'src/components/common/flex-box/FlexBox';
import Text from 'src/components/common/text/Text';
import LinkAsButton from 'src/components/common/link-as-button/LinkAsButton';
import Icon from 'src/components/common/icon/Icon';

const getBackgroundColor = (style) => {
  switch (true) {
    case style === 'light-purple':
      return 'var(--purple-shadow)';

    case style === 'orange':
      return 'var(--orange-plan)';

    case style === 'purple':
      return 'var(--purple)';

    default:
      return 'var(--gray)';
  }
};

const Title = styled(Text)`
  font-size: 2.4rem;
  font-weight: 700;
`;

const Description = styled(Text)`
  font-size: 1.4rem;
  line-height: 1.5;
`;

const PriceText = styled.span`
  font-size: 1.6rem;
`;

const PriceValue = styled.span`
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: -0.4rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
`;

const Wrapper = styled(FlexBox)`
  background-color: ${({ color }) => getBackgroundColor(color)};
  border-radius: 1.6rem;
  padding: ${({ isMobile }) => (isMobile ? '1.6rem' : '2rem')};
  position: relative;
  transition: all ease 0.5s;

  ${Title},
  ${Description},
  ${PriceValue},
  ${PriceText} {
    color: ${({ color }) =>
      color === 'purple' ? 'var(--white)' : 'var(--purple)'};
  }

  ${({ isMobile }) =>
    !isMobile &&
    css`
      &:hover {
        filter: drop-shadow(0 3rem 2rem var(--purple-highlight));
        transform: translateY(-1.5rem);
        transition: all ease 0.5s;
      }
    `}
`;

const CallToAction = styled(LinkAsButton)`
  text-align: center;
`;

const RecommendedLabel = styled.div`
  background-color: var(--white-variant);
  border-radius: 0.4rem;
  color: var(--purple-pill-hover);
  font-weight: 600;
  padding: 0.5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const List = styled.ul`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  list-style: none;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const Item = styled.li`
  align-items: flex-start;
  display: flex;
  font-size: 1.4rem;
  font-weight: 400;
  justify-content: flex-start;

  ${({ isWhite, isCross }) => {
    switch (true) {
      case isWhite:
        return css`
          color: var(--white);
        `;

      case isCross:
        return css`
          color: var(--gray);
        `;

      default:
        return css`
          color: var(--purple);
        `;
    }
  }}
`;

const CrossIcon = styled(Icon)`
  margin: 0.25rem 1.5rem 0 1.5rem;

  path {
    stroke: var(--gray-lighter);
  }
`;

const TickIcon = styled(Icon)`
  margin: 0.25rem 1.5rem 0 1.5rem;

  path {
    stroke: ${({ isWhite }) => (isWhite ? 'var(--white)' : 'var(--purple)')};
  }
`;

const Plan = ({
  name,
  description,
  isRecommended,
  recommendedLabel,
  price,
  features,
  callToAction,
  color,
  currency,
  priceType,
  ...remainingProps
}) => {
  if (typeof window === 'undefined') return null;

  const { sm: isMobile } = useBreakpoint();

  return (
    <Wrapper
      isMobile={isMobile}
      color={color}
      alignSelf="stretch"
      flexDirection="column"
      gap="4.4rem"
      width={isMobile ? '100%' : '31%'}
      {...remainingProps}
    >
      {isRecommended && (
        <RecommendedLabel isMobile={isMobile}>
          {recommendedLabel}
        </RecommendedLabel>
      )}
      <FlexBox flexDirection="column" gap="0.5rem">
        <Title>{name}</Title>
        <Description>{description}</Description>
      </FlexBox>
      <List>
        {features.map(({ description, is_tick: isTick }, index) => (
          <Item
            key={`feature-${index}`}
            isWhite={color === 'purple'}
            isCross={!isTick}
          >
            {isTick ? (
              <TickIcon
                isWhite={color === 'purple'}
                icon="tick"
                onLoad={(e) => SVGInject(e.target)}
              />
            ) : (
              <CrossIcon icon="cross" onLoad={(e) => SVGInject(e.target)} />
            )}
            {description}
          </Item>
        ))}
      </List>
      <FlexBox
        alignItems="flex-end"
        justifyContent="center"
        width="100%"
        css={css`
          margin-top: auto;
        `}
      >
        <PriceText>{currency}</PriceText>
        <PriceValue>{price[priceType].value}</PriceValue>
        <PriceText>/{price[priceType].text}</PriceText>
      </FlexBox>
      <CallToAction to={callToAction.link} {...{ [callToAction.type]: true }}>
        {callToAction.text}
      </CallToAction>
    </Wrapper>
  );
};

const priceType = PropTypes.shape({
  text: PropTypes.string,
  value: PropTypes.number,
});

Plan.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  isRecommended: PropTypes.bool,
  recommendedLabel: PropTypes.string,
  price: PropTypes.shape({
    yearly: priceType,
    monthly: priceType,
  }),
  features: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      is_tick: PropTypes.bool,
    }),
  ),
  callToAction: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  }),
  color: PropTypes.oneOf(['purple', 'light-purple', 'orange']),
  currency: PropTypes.string,
  priceType: PropTypes.oneOf(['yearly', 'monthly']),
};

export default Plan;
