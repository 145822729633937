import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useRenderTitle from 'src/hooks/useRenderTitle';

import Section from 'src/components/common/section/Section';
import FlexBox from 'src/components/common/flex-box/FlexBox';
import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import PartnerLogo from 'src/components/common/partner-logo/PartnerLogo';
import Text from 'src/components/common/text/Text';

const StyledSection = styled(Section)`
  background-color: var(--gray-section);
  padding: ${({ isMobile }) => (isMobile ? '5rem 2rem' : '10rem 0')};
`;

const PartnerLogoWrapper = styled(FlexBox)`
  padding: 2rem;
`;

const Partners = () => {
  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();

  const title = t('sections.partners.title', { returnObjects: true });
  const text = t('sections.partners.text');
  const partners = t('sections.partners.partners', { returnObjects: true });

  const { render: renderTitle } = useRenderTitle({
    titleComponent: Title,
    highlightComponent: HighlightedText,
    text: title,
  });

  return (
    <StyledSection isMobile={isMobile}>
      <FlexBox
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={isMobile ? 'flex-start' : 'space-between'}
        width="100%"
        rowGap="2rem"
      >
        <FlexBox
          flexDirection="column"
          gap="2rem"
          width={isMobile ? '100%' : '40%'}
        >
          {renderTitle()}
          <Text>{text}</Text>
        </FlexBox>
        <FlexBox flexWrap="wrap" width={isMobile ? '100%' : '50%'}>
          {partners.map((partner, index) => (
            <PartnerLogoWrapper
              key={`partner-doctor-${index}`}
              width={isMobile ? '50%' : '25%'}
            >
              <PartnerLogo
                partner={partner}
                height="auto"
                width="100%"
                css={
                  isMobile &&
                  css`
                    max-height: 3rem;
                  `
                }
              />
            </PartnerLogoWrapper>
          ))}
        </FlexBox>
      </FlexBox>
    </StyledSection>
  );
};

export default Partners;
