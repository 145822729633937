import React from 'react';
import { graphql } from 'gatsby';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import HeaderTags from 'src/components/common/header-tags/HeaderTags';
import PageWrapper from 'src/components/common/page-wrapper/PageWrapper';
import Header from 'src/components/common/header/Header';
import Faqs from 'src/components/common/faqs/Faqs';
import Footer from 'src/components/common/footer/Footer';

import DoctorHero from 'src/components/doctor/doctor-hero/DoctorHero';
import Partners from 'src/components/doctor/partners/Partners';
import Features from 'src/components/doctor/features/Features';
import Schedule from 'src/components/doctor/schedule/Schedule';
import Telemedicine from 'src/components/doctor/telemedicine/Telemedicine';
import Testimonials from 'src/components/doctor/testimonials/Testimonials';
import Plans from 'src/components/doctor/plans/Plans';

const DoctorIndexPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderTags
        title={t('page.title')}
        metaItems={t('page.meta', { returnObjects: true })}
      />
      <PageWrapper>
        <Header pageType="doctor" />
        <DoctorHero />
        <Features />
        <Schedule />
        <Telemedicine />
        <Testimonials />
        <Plans />
        <Partners />
        <Faqs
          title={t('sections.faqs.title', { returnObjects: true })}
          faqs={t('sections.faqs.faqs', { returnObjects: true })}
        />
        <Footer />
      </PageWrapper>
    </>
  );
};

export default DoctorIndexPage;

export const query = graphql`
  query ($language: String! = "pt-BR") {
    locales: allLocale(
      filter: { ns: { in: ["common", "doctor"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
