import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import FlexBox from 'src/components/common/flex-box/FlexBox';

const Wrapper = styled.div`
  display: block;
  height: 3.6rem;
  margin: 2rem 0;
  position: relative;
  width: ${({ isMobile }) => (isMobile ? '100%' : '35rem')};
`;

const FirstPill = styled(FlexBox)`
  border-radius: 10% / 50%;
  background: var(--gray-border);
  color: var(--gray-darker);
  font-size: 1.4rem;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
`;

const SecondPill = styled(FlexBox)`
  border-radius: 15% / 50%;
  background: var(--purple);
  color: var(--gray-section);
  font-size: 1.4rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
`;

const TelemedicineDetails = ({ details, ...remainingProps }) => {
  const { sm: isMobile } = useBreakpoint();

  return (
    <Wrapper isMobile={isMobile} {...remainingProps}>
      <FirstPill
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="70%"
      >
        {details[0]}
      </FirstPill>
      <SecondPill
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="40%"
      >
        {details[1]}
      </SecondPill>
    </Wrapper>
  );
};

TelemedicineDetails.propTypes = {
  details: PropTypes.arrayOf(PropTypes.string),
};

export default TelemedicineDetails;
