import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useRenderTitle from 'src/hooks/useRenderTitle';

import Section from 'src/components/common/section/Section';
import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import Subtitle from 'src/components/common/subtitle/Subtitle';
import FlexBox from 'src/components/common/flex-box/FlexBox';
import Icon from 'src/components/common/icon/Icon';
import Text from 'src/components/common/text/Text';
import LinkAsButton from 'src/components/common/link-as-button/LinkAsButton';

import browserPlaceholder from './images/browserPlaceholder.png';

const StyledSection = styled(Section)`
  padding: ${({ isMobile }) => (isMobile ? '5rem 1.6rem' : '5rem 0')};
`;

const StyledLinkAsButton = styled(LinkAsButton)`
  padding-left: 5rem;
  padding-right: 5rem;
  margin-top: ${({ isMobile }) => (isMobile ? '1rem' : '3rem')};
  text-align: center;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
`;

const List = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style: none;
  margin: 1rem 0 0;
  padding: 0;
`;

const Item = styled.li`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  gap: 2rem;

  &:not(:last-of-type) {
    margin-bottom: 2.6rem;
  }
`;

const TickWrapper = styled(FlexBox)`
  background-color: var(--purple);
  border-radius: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 0.5rem;
`;

const StyledText = styled(Text)`
  color: var(--gray-text);
`;

const Grid = styled.div`
  align-self: stretch;
  display: grid;
  grid-template-columns: 5rem calc(598px - 8rem) 3rem;
  grid-template-rows: 15rem calc(473px - 15rem) 15.1rem;
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
`;

const ImageWrapper = styled(FlexBox)`
  grid-column: 1 / 3;
  grid-row: 1 / 3;
`;

const StyledImg = styled.img`
  border: 0;
  object-fit: contain;
`;

const TextWrapper = styled(FlexBox)`
  background-color: var(--turquoise-darker);
  border-radius: 1.6rem;
  grid-column: 2 / 3;
  grid-row: 2 / 4;
  padding: 2.8rem 3.2rem;
  z-index: -1;
`;

const Testimonial = styled(Text)`
  color: var(--purple);
`;

const Schedule = () => {
  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();

  const title = t('sections.schedule.title', { returnObjects: true });
  const subtitle = t('sections.schedule.subtitle');
  const bulletPoints = t('sections.schedule.bullet_points', {
    returnObjects: true,
  });
  const { link, text: callToAction } = t('sections.schedule.call_to_action', {
    returnObjects: true,
  });
  const {
    text: testimonial,
    author,
    profession,
  } = t('sections.schedule.testimonial', { returnObjects: true });

  const { render: renderTitle } = useRenderTitle({
    titleComponent: Title,
    highlightComponent: HighlightedText,
    text: title,
  });

  return (
    <StyledSection isMobile={isMobile}>
      <FlexBox flexDirection={isMobile ? 'column' : 'row'} width="100%">
        <FlexBox
          alignSelf="stretch"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          gap="1.5rem"
          width={isMobile ? '100%' : '50%'}
        >
          <Subtitle orange>{subtitle}</Subtitle>
          {renderTitle()}
          <List>
            {bulletPoints.map((point, index) => (
              <Item key={`bullet-point-${index}`}>
                <TickWrapper
                  alignItems="center"
                  justifyContent="center"
                  height="3rem"
                  width="3rem"
                >
                  <Icon icon="tick" height="auto" width="1.2rem" />
                </TickWrapper>
                <StyledText>{point}</StyledText>
              </Item>
            ))}
          </List>
          <StyledLinkAsButton isMobile={isMobile} to={link}>
            {callToAction}
          </StyledLinkAsButton>
        </FlexBox>
        {!isMobile && (
          <Grid>
            <ImageWrapper>
              <StyledImg src={browserPlaceholder} height="auto" width="100%" />
            </ImageWrapper>
            <TextWrapper flexDirection="column" justifyContent="flex-end">
              <Testimonial
                css={css`
                  font-style: italic;
                  margin-bottom: 3rem;
                `}
              >
                {testimonial}
              </Testimonial>
              <Testimonial>
                <strong
                  css={css`
                    font-size: ${isMobile ? '1.6rem' : '1.8rem'};
                    margin-right: 2rem;
                  `}
                >
                  {author}
                </strong>
                {profession}
              </Testimonial>
            </TextWrapper>
          </Grid>
        )}
      </FlexBox>
    </StyledSection>
  );
};

export default Schedule;
