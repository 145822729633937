import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';

import FlexBox from 'src/components/common/flex-box/FlexBox';

const Label = styled.label`
  align-items: center;
  display: flex;
  gap: 2.5rem;
  justify-content: center;
`;

const Name = styled.span`
  color: var(--purple);
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: ${({ isSelected }) => (isSelected ? '700' : 'normal')};
  min-width: 5rem;
`;

const Switch = styled.div`
  background-color: ${({ isOn }) =>
    isOn ? 'var(--purple)' : 'var(--purple-dropshadow)'};
  border-radius: 6.2rem;
  cursor: pointer;
  height: 2.5rem;
  position: relative;
  transition: all ease 0.4s;
  width: 5rem;

  &:before {
    background-color: ${({ isOn }) =>
      isOn ? 'var(--orange)' : 'var(--orange-highlight)'};
    border-radius: 50%;
    content: '';
    height: 1.8rem;
    position: absolute;
    top: 0.3rem;
    width: 1.8rem;
    transition: all ease 0.4s;

    ${({ isOn }) =>
      isOn
        ? css`
            right: 0.3rem;
            transition: all ease 0.4s;
          `
        : css`
            left: 0.3rem;
            transition: all ease 0.4s;
          `}
  }
`;

const Checkbox = styled.input`
  height: 0;
  opacity: 0;
  width: 0;
`;
Checkbox.defaultProps = { type: 'checkbox' };

const Toggle = ({ options, selected, onChange, ...remainingProps }) => {
  const [{ name: offOption }, { id: on, name: onOption }] = options;
  const [isOn, setIsOn] = useState(selected.id === on);
  const id = uniqid();

  const handleOnChange = () => {
    setIsOn(!isOn);
    onChange(!isOn ? { ...options[1] } : { ...options[0] });
  };

  return (
    <FlexBox
      alignItems="center"
      justifyContent="center"
      width="auto"
      {...remainingProps}
    >
      <Label htmlFor={id}>
        <Name isSelected={!isOn}>{offOption}</Name>
        <FlexBox>
          <Checkbox
            id={id}
            checked={isOn}
            isOn={isOn}
            onChange={handleOnChange}
          />
          <Switch isOn={isOn} />
        </FlexBox>
        <Name isSelected={isOn}>{onOption}</Name>
      </Label>
    </FlexBox>
  );
};

const optionType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
});

Toggle.propTypes = {
  options: PropTypes.arrayOf(optionType).isRequired,
  selected: optionType,
  onChange: PropTypes.func,
};

export default Toggle;
