import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useRenderTitle from 'src/hooks/useRenderTitle';

import Hero from 'src/components/common/hero/Hero';
import FlexBox from 'src/components/common/flex-box/FlexBox';
import Logo from 'src/components/common/logo/Logo';
import Link from 'src/components/common/link/Link';
import LinkAsButton from 'src/components/common/link-as-button/LinkAsButton';
import LinkAsDropdown from 'src/components/common/link-as-dropdown/LinkAsDropdown';
import Subtitle from 'src/components/common/subtitle/Subtitle';
import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import HighlightedImage from 'src/components/common/highlighted-image/HighlightedImage';
import PartnerLogo from 'src/components/common/partner-logo/PartnerLogo';
import Text from 'src/components/common/text/Text';

import heroImage from 'src/images/doctor/drconecta-doctor-hero.png';

const Row = styled(FlexBox)`
  padding: ${({ isMobile }) => (isMobile ? '0 0 2rem 0' : '3.8rem 0 5rem 0')};
`;

const List = styled.ul`
  align-items: center;
  display: flex;
  gap: 4rem;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  margin: 0;
  padding: 0;
`;

const StyledLink = styled(Link)`
  font-size: 1.4rem;
  font-weight: 600;
`;

const StyledLinkAsButton = styled(LinkAsButton)`
  padding-left: 4rem;
  padding-right: 4rem;
`;

const StyledLinkAsDropdown = styled(LinkAsDropdown)`
  padding-left: 4rem;
  padding-right: 4rem;
`;

const StyledText = styled(Text)`
  color: var(--gray-text);
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const CallToAction = styled(LinkAsButton)`
  margin-bottom: 2rem;
  max-width: 20rem;
  text-align: center;
  width: auto;
`;

const DoctorHero = () => {
  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();

  const title = t('sections.hero.title', { returnObjects: true });
  const subtitle = t('sections.hero.subtitle');
  const text = t('sections.hero.text');
  const links = t('sections.hero.links', { returnObjects: true });
  const { link: callToActionLink, text: callToActionText } = t(
    'sections.hero.call_to_action',
    { returnObjects: true },
  );
  const partners = t('sections.hero.partners', { returnObjects: true });

  const { render: renderTitle } = useRenderTitle({
    titleComponent: Title,
    highlightComponent: HighlightedText,
    text: title,
  });

  const renderPartners = () => (
    <FlexBox
      alignItems="flex-end"
      justifyContent={isMobile ? 'center' : 'flex-start'}
      gap="2rem"
      width="100%"
      css={
        isMobile &&
        css`
          margin: 5rem 0 3rem 0;
        `
      }
    >
      {partners.map((partner, index) => (
        <PartnerLogo
          key={`partner-${index}`}
          grayscale
          partner={partner}
          height="28rem"
          width="auto"
        />
      ))}
    </FlexBox>
  );

  return (
    <Hero
      id="home"
      css={
        isMobile &&
        css`
          padding: 2rem;
        `
      }
    >
      <Row
        isMobile={isMobile}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Logo type="doctor" height="auto" width={isMobile ? 160 : 220} />
        {!isMobile && (
          <List>
            {links.map(({ link, name, type, sub_links, tertiary }, index) => (
              <Item key={`link-${index}`}>
                {type === 'link' ? (
                  <StyledLink to={link}>{name}</StyledLink>
                ) : false}

                {type === 'linkAsButton' ? (
                  <StyledLinkAsButton to={link}>{name}</StyledLinkAsButton>
                ) : false}

                {type === 'linkAsDropdown' ? (
                    <StyledLinkAsDropdown to={link} sub_links={sub_links} tertiary={tertiary}>
                      {name}
                    </StyledLinkAsDropdown>
                ) : false}
              </Item>
            ))}
          </List>
        )}
      </Row>
      <FlexBox
        flexDirection={isMobile ? 'column' : 'row'}
        alignSelf="stretch"
        justifyContent="space-between"
        width="100%"
      >
        <FlexBox
          flexDirection="column"
          alignSelf="stretch"
          aligItems="flex-start"
          justifyContent="center"
          gap="1rem"
          height="auto"
          width={isMobile ? '100%' : '50%'}
        >
          <Subtitle orange>{subtitle}</Subtitle>
          {renderTitle()}
          <StyledText>{text}</StyledText>
          {!isMobile ? (
            <CallToAction secondary to={callToActionLink}>
              {callToActionText}
            </CallToAction>
          ) : (
            <LinkAsButton
              primary
              to={callToActionLink}
              css={css`
                text-align: center;
                margin-bottom: 2rem;
              `}
            >
              {callToActionText}
            </LinkAsButton>
          )}
          {!isMobile && renderPartners()}
        </FlexBox>
        <FlexBox width={isMobile ? '100%' : '45%'}>
          <HighlightedImage
            purple
            isResponsive
            source={heroImage}
            height="auto"
            width="100%"
          />
        </FlexBox>
        {isMobile && renderPartners()}
      </FlexBox>
    </Hero>
  );
};

export default DoctorHero;
