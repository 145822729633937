import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useRenderTitle from 'src/hooks/useRenderTitle';

import Section from 'src/components/common/section/Section';
import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import Text from 'src/components/common/text/Text';
import FlexBox from 'src/components/common/flex-box/FlexBox';
import Toggle from 'src/components/common/toggle/Toggle';

import Plan from './components/plan/Plan';

const StyledSection = styled(Section)`
  margin: 5rem 0;
  padding: ${({ isMobile }) => (isMobile ? '5rem 2rem' : '5rem 0')};
`;

const StyledText = styled(Text)`
  color: var(--gray-text);
  margin-bottom: 4.7rem;
`;

const Plans = () => {
  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();

  const title = t('sections.plans.title', { returnObjects: true });
  const text = t('sections.plans.text');
  const options = t('sections.plans.options', { returnObjects: true });
  const currency = t('sections.plans.currency');
  const recommended = t('sections.plans.recommended');
  const plans = t('sections.plans.plans', { returnObjects: true });

  const [planType, setPlanType] = useState(options[0]);

  const { render: renderTitle } = useRenderTitle({
    titleComponent: Title,
    highlightComponent: HighlightedText,
    text: title,
  });

  const handleOnChange = (option) => setPlanType(option);

  return (
    <StyledSection id="prices" isMobile={isMobile}>
      <FlexBox
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="2rem"
      >
        {renderTitle()}
        <StyledText align={isMobile ? 'left' : 'center'}>{text}</StyledText>
        {/* <FlexBox
          alignItems="center"
          justifyContent="center"
          css={css`
            margin-bottom: 4.5rem;
          `}
        >
          <Toggle
            options={options}
            selected={planType}
            onChange={handleOnChange}
          />
        </FlexBox> */}
        <FlexBox
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          justifyContent="space-between"
          gap="2rem"
          width="100%"
        >
          {plans.map(
            (
              {
                name,
                description,
                is_recommended: isRecommended,
                price,
                features,
                call_to_action: callToAction,
                color,
              },
              index,
            ) => (
              <Plan
                key={`plan-${index}`}
                name={name}
                description={description}
                isRecommended={isRecommended}
                recommendedLabel={recommended}
                price={price}
                features={features}
                callToAction={callToAction}
                color={color}
                currency={currency}
                priceType={planType.name === 'Anual' ? 'yearly' : 'monthly'}
              />
            ),
          )}
        </FlexBox>
      </FlexBox>
    </StyledSection>
  );
};

export default Plans;
