import React from 'react';
import styled from '@emotion/styled';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useRenderTitle from 'src/hooks/useRenderTitle';

import Section from 'src/components/common/section/Section';
import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import Subtitle from 'src/components/common/subtitle/Subtitle';
import FlexBox from 'src/components/common/flex-box/FlexBox';
import Text from 'src/components/common/text/Text';
import LinkAsButton from 'src/components/common/link-as-button/LinkAsButton';

import TelemedicineDetails from './components/telemedicine-details/TelemedicineDetails';

import telemedicine from './images/telemedicina.png';

const StyledSection = styled(Section)`
  padding: ${({ isMobile }) => (isMobile ? '5rem 1.6rem' : '5rem 0')};
`;

const Image = styled.img`
  border: 0;
  object-fit: contain;
`;

const StyledText = styled(Text)`
  color: var(--gray-text);
`;

const StyledLinkAsButton = styled(LinkAsButton)`
  margin-top: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  text-align: center;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
`;

const Telemedicine = () => {
  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();

  const title = t('sections.telemedicine.title', { returnObjects: true });
  const subtitle = t('sections.telemedicine.subtitle');
  const text = t('sections.telemedicine.text');
  const details = t('sections.telemedicine.details', { returnObjects: true });
  const { link, text: callToAction } = t(
    'sections.telemedicine.call_to_action',
    {
      returnObjects: true,
    },
  );

  const { render: renderTitle } = useRenderTitle({
    titleComponent: Title,
    highlightComponent: HighlightedText,
    text: title,
  });

  const rowProps = {
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: '1rem',
  };

  return (
    <StyledSection isMobile={isMobile}>
      <FlexBox
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
      >
        <FlexBox width={isMobile ? '100%' : '45%'} {...rowProps}>
          {isMobile && <Subtitle orange>{subtitle}</Subtitle>}
          {isMobile && renderTitle()}
          <Image src={telemedicine} height="auto" width="100%" />
        </FlexBox>
        <FlexBox width={isMobile ? '100%' : '50%'} {...rowProps}>
          {!isMobile && <Subtitle orange>{subtitle}</Subtitle>}
          {!isMobile && renderTitle()}
          <TelemedicineDetails details={details} />
          <StyledText>{text}</StyledText>
          <StyledLinkAsButton isMobile={isMobile} to={link}>
            {callToAction}
          </StyledLinkAsButton>
        </FlexBox>
      </FlexBox>
    </StyledSection>
  );
};

export default Telemedicine;
