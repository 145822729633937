import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import FlexBox from 'src/components/common/flex-box/FlexBox';
import Text from 'src/components/common/text/Text';

import cardBackground from './images/drconecta-doctor-testimonials-card-bg.png';

const StyledFlexBox = styled(FlexBox)`
  background-color: var(--white);
  background-position: ${({ isMobile }) =>
    isMobile ? '1.8rem 1.8rem' : '3.2rem 3.8rem'};
  background-repeat: no-repeat;
  border-radius: 0.8rem;
  flex-shrink: 0;
  padding: ${({ isMobile }) =>
    isMobile ? '4.8rem 1.8rem 1.8rem' : '3.2rem 3.2rem 3.2rem 6.4rem'};
`;

const Name = styled(Text)`
  color: var(--purple);
  font-size: ${({ isMobile }) => (isMobile ? '1.8rem' : '2rem')};
  font-weight: 700;
  margin-top: auto;
  padding-top: 2.4rem;
`;

const Subtitle = styled(Text)`
  color: var(--gray);
  font-size: ${({ isMobile }) => (isMobile ? '1.4rem' : '1.6rem')};
`;

const Card = ({ text, name, subtitle }) => {
  const { sm: isMobile } = useBreakpoint();

  return (
    <StyledFlexBox
      isMobile={isMobile}
      alignSelf="stretch"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      style={{ backgroundImage: `url(${cardBackground})` }}
      width={isMobile ? '70%' : '44.5rem'}
    >
      <Text>{text}</Text>
      <Name isMobile={isMobile}>{name}</Name>
      <Subtitle isMobile={isMobile}>{subtitle}</Subtitle>
    </StyledFlexBox>
  );
};

Card.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default Card;
